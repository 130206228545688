//*{outline: 1px solid red}

/*************************************************
// IMPORT SASS FOR PROJECT
*************************************************/

@import 'bootstrap-variables';
//@import 'icomoon/icomoon';
@import 'bootstrap/bootstrap';
@import 'hover/hover.scss';

/*************************************************
// YOUR CUSTOM SASS HERE
*************************************************/

/* 	------------------------- Author: @flocoder ----------------------------- */

/* 	------------------------- CSS Variables ----------------------------- */

$primary-color: 	#5cc4b7;

$lato: 'Lato', Georgia, "Times New Roman", Times, serif;
$droid:'Droid Sans', Georgia, "Times New Roman", Times, serif;
$mada:'Mada',Georgia, "Times New Roman", Times, serif;


/* 	------------------------- Default element CSS ----------------------------- */


body{ 	
	background-color: #ffffff;
	font-family:$droid;
	height: 100%;
	position: relative;	

}



body,p{
	color: #000000;
	font-family:$droid;
}


p{
	font-size: 14px;	
}

h1,h2,h3,h4,h5,h6{
	font-family: $lato;
	font-weight: bold;
	color: #1a5061;
	margin-top:0;
}

a{
	&:hover{
		text-decoration: none;
	}
}

h1{ font-size: 36px; }
h2{ font-size: 33px; }
h3{ font-size: 30px; }
h4{ font-size: 27px; }
h5{ font-size: 24px; }

/* 	------------------------- Mixin CSS ----------------------------- */

@mixin aimpression($left,$bottom,$direction,$rotate_toggle:1){
	position: absolute;	
    text-align: left;
    font-size: 13px; 

    @if $rotate_toggle == 1{
    	-webkit-transform:rotate(-90deg);
    	#{$direction}: $left;
		bottom: $bottom;
    }@else{
    	-webkit-transform:none !important;    	
    	color: #ffffff;
    	#{$direction}: 8px !important;
		bottom: 8px !important;
    }

	#{$direction}: $left;
	bottom: $bottom; 
}

/* 	------------------------- Media Queries CSS ----------------------------- */


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1635px){
	.image-description{
		position: static;			
		width: 100% !important;
		display: block;		

	}
	
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1560px){
	
	.gallery1{
		td{
			img{
				padding-bottom: 0px !important;
			}
		}
	}

	.brochure-spacing{
		margin-top:0 !important;
	}

	.white-space-indulge{
		padding-top: 109px !important;
	}

	.white-space-lg{
		padding:10px 0 !important;
	}

	.photo-desc-right{
		width: 314px !important;
		padding-left: 37px !important;
	}

	h1{
		font-size: 30px !important;
	}
	
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1040px){

	.main-menu {
       -webkit-transform:none !important;
    	width: auto !important;
    	display: none !important;

	}

	.slicknav_menu{
		display: block !important;
	}

	.enquire-now-mobile{
		display: block !important;
	}

	.enquire-now{
		display: none !important;
	}
	#mySidenav{
		display: none !important;
	}

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px){

	.photo-desc-right{
		width: auto !important;
		padding-left: 15px !important;
	}

	.white-space-indulge{
		padding-top: 0px !important;
	}



	.main-nav{
		-webkit-transform:none !important;
	}


	.white-space-lg{
		padding: 5px 0 !important;
	}
		
	.select-custom{
		.form-control{
			margin-bottom: 10px !important;			
		}
	}

	.exterior-artist-impression1{
		@include aimpression(10px,8px,left,0);
	}

	.exterior-artist-impression2{
		@include aimpression(12px,7px,right,0);
	}

	.exterior-artist-impression3{
		@include aimpression(-137px,91px,left,0);
	}

	.artist-impression1{
		@include aimpression(-99px,56px,left,0);
	}

	.artist-impression2{
		@include aimpression(-80px,56px,right,0);
	}

	.artist-impression3{
		@include aimpression(-89px,45px,left,0);
	}

	.artist-impression4{
		@include aimpression(-89px,45px,left,0);
	}

	.artist-impression5{
		@include aimpression(-71px,33px,right,0);
	}

	.artist-impression6{
		@include aimpression(-81px,37px,left,0);
	}

	.valignment{
		vertical-align: top !important;
	}

	.team-align{
		text-align: center;
	}
	.indulge-small-screen{
		display: block !important; 
	}
	.indulge-big-screen {
		display: none;
	}

	.gallery-first, .gallery-second{

		.nopadding-left{
			padding-left: 15px !important;
		}

		.nopadding-right{
			padding-right:  15px !important;
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px){
	
	.logo-wrapper{
		img{
			width: 240px !important;
		}
	}

	.table-wrapper{

		table{
			width: 100%;

			tr{
				display:block;
			}

			td{
				display: block;
				width: 100% !important;
			}
		}

	}

	.photo-desc-right{
		width: auto !important;
		padding-left: 15px !important;
	}

	.photo-desc-left{
		width: auto !important;
		padding-right: 15px !important;
	}

	.navbar-default .navbar-collapse, .navbar-default .navbar-form{
		float: left !important;
	}

	.white-space-lg{
		padding: 5px 0 !important;
	}

	.white-space-sm{
		padding: 0 !important;
	}

	.large-screen{
		display: none !important; 
	}

	.dim-background{
		padding: 15px 0 !important;
	}



}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px){
	
	.footer-header{
		display: none !important;
	}

	.small-screen{
		display: block !important; 
	}
	

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px){
	
	
}


/* 	------------------------- Header CSS ----------------------------- */



.small-screen{
	display: none;
}

.indulge-small-screen{
	display: none;
}

.enquire-now-mobile{


	z-index: 8;
	text-align: center;
	width: 200px;
	margin:0 auto;
	margin-top: 7px;
	display: none;
	.btn-primary{
		background: none;
		border:none;
		a{
			color: #fff;
			background:none;
		}
	}
}


#mainmenu{
	width: 569px;
	margin:0 auto;
	overflow:hidden;
}

.main-menu {

	font-family: $lato;
	font-weight: bold;
	text-align: center;
	background-color:  #e7f2da;
  z-index: 99;
  width:100vh;    
   position:fixed;
  -webkit-transform-origin: left top;
  -webkit-transform:rotate(-90deg) translateX(-100%);

	  ul.nav li {    
	 
	  float:left;
	  height:100%;
	 
	  a{

	  	color: #1a5061;
	  	padding: 10px;
	  	&:hover, &:focus, &:active{
	  		background: none;
	  		color: #5cc4b7;
	  	}
	  }
	}
}

.main-nav {
    width: 100%;
    left: 10px;
    top: 0;
    position:fixed;  
    -webkit-transform-origin: left top;
    -webkit-transform:rotate(-90deg) translateX(-100%);
    z-index: 99;

    ul.nav {    
	    margin:0 auto;
	    height:100%;        
	}

	ul.nav li { 	  
	    
	    height:100%;
	    
	}

	ul.nav li a {           
	    color:#fff;    
	}

}

.navbar .navbar-nav {

	a{
		font-weight: bold;
		font-size: 14px;
		color: #fff;
	}		
}

.navbar-default{

	-webkit-transform: rotate(-90deg) translate(20px,30px);
	background:none;
	border:none;
	font-family: $lato;
  
}


.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
	background: none;
	color: $primary-color;
}

.navbar-default .navbar-nav > li > a{
	color: #1a5061;
	
}

.menu-holder{
	position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
	padding: 5px 0;
}

.register-now-menu{
	
	font-family: $lato;
	font-weight: bold;	
	a{
		span{			
			padding: 8px 15px;
			color: #FFFFFF;
			background-color: $primary-color;
		}
	}
	
}

.enquire-now-wrapper{
	height: 100vh;    
  
	  position:fixed;
	  right: 0;
	  
	 display: flex;
	  align-items: center;
	 
	   z-index: 999;

}


.enquire-now-menu{
	display: none !important;
}

/* 	------------------------- Off-Canvas Menu ----------------------------- */

.top-register-bar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    background: url('../img/bg-footer.jpg');
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    background: url('../img/bg-footer.jpg');
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover, .offcanvas a:focus{
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 36px;
    color: #ffffff;    
}

#main {
    transition: margin-right .5s;

}

/* 	------------------------- Body CSS ----------------------------- */



	p{
		overflow: hidden;
	}


.modal-content{
	 background: url('../img/bg-footer.jpg');
}

.close{
		position: absolute; font-size: 40px; right: 8px;top:1px; color: #fff;
		opacity: 10;
		z-index: 9999;
}

.carousel-control.left, .carousel-control.right {
     background-image:none !important;
  	 filter:none !important;
	top: 100px;
    bottom: 100px;
    width: 5%;
}

.item{
	position: relative;
}

.content-holder{

}
#more-info{
	display: none;
}

.more-info-wrap{
	position: absolute; bottom: 80px; left: 20px; z-index: 1
}

.span6{
	float:right;
}

.image-description{
	position: absolute; 
	right: 1184px; 
	top: 200px; 
	width: 340px;
	
}

.photo-desc-left{
	width: 360px;
	padding-right: 50px;
	
}

.photo-desc-right{
	width: 360px;	
	padding-left: 50px;
}


.exterior-artist-impression1{
	@include aimpression(-71px,40px,left);
}

.exterior-artist-impression2{
	@include aimpression(-69px,43px,right);
}

.exterior-artist-impression3{
	@include aimpression(-73px,40px,left);
}

.artist-impression1{
	@include aimpression(-71px,41px,left);
}

.artist-impression2{
	@include aimpression(-63px,42px,right);
}

.artist-impression3{
	@include aimpression(-69px,41px,left);
}

.artist-impression4{
	@include aimpression(-68px,41px,left);
}

.artist-impression5{
	@include aimpression(-62px,42px,right);
}

.artist-impression6{
	@include aimpression(-72px,39px,left);
}

.team{

	p{
		color: #ffffff;

		a{
			color: #5ec4b6;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	
}

.gallery-title{
	h1{
		margin: 0;
	}
}

.dim-background{
	background-color: #e7f2da;
	padding: 50px 0;
}

.valignment{
		vertical-align: middle;
}

#ebrochure{
	a{
		font-size: 36px;
		font-family: $lato;
		color: #1a5061;
		font-weight: bold;
		line-height: 1em;
		&:hover{
			color:#5ec4b6;
		}
	}
}

/* 	------------------------- Masonry CSS ----------------------------- */
.grid-item { width: 360px; }

/* 	------------------------- Form CSS ----------------------------- */

.nugget {
  text-align: center;
  width: 100%;
  margin: 0px auto;
  padding: 0 15px;
	label{
		font-size: 1.2rem;
		font-family: Arial;
		
	}
	
}

.error {
  color: red !important;
}

.reveal {
  color: white;
}

#footer .row {
  margin-top: 60px;
}

.btn-normal:hover,
.btn-submit:hover,
.btn-normal,
.btn-submit {
	background: $primary-color;
	color: #ffffff;
	border: none;
	padding: 5px 15px;  
	margin: 0;
	font-weight: 700;
	font-family: $lato;
	font-size: 18px;
	text-decoration: none;
	border-radius: 0 !important;
}

label {
  
  font-size: 0.9rem;
  
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
  display: block;
  box-sizing: border-box;
  width: 100%; 
  margin: 0 0 1rem;
  padding: 7px;
  border: none;
  border: 1px solid $primary-color;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  font-family: $droid;
  font-weight: normal;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #ffffff;
}

input::placeholder {
	color: #ffffff;
	font-family: $droid;	
}

.select-custom{

	select{

		background: #0d5161;
		color: #ffffff;
		border: 1px solid $primary-color;
		border-radius: 0 !important;
		-webkit-appearance: none;		
	
	}

}


[type='checkbox'] + label, [type='radio'] + label {
  margin-right: 0;
}

[type='checkbox'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

input.chkrad {
  display: none;
  font-size: 16px;
}

input.chkrad + label {
  color: #fff;
}

input.chkrad + label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -1px 4px 0 0;
  vertical-align: baseline;
  cursor: pointer;
}

input + label span {
  background: transparent;
  line-height: 100%;
  outline: 1px solid #fff;
}

input.X:checked + label span {
  padding: -3px 0 3px;
}

input.X:checked + label span:before {
  content: "\2713";
  color: #fff;
  font-family: helvetica;
  font-style: oblique;
  font-size: 26px;
}

div.checkboxinput {
  text-align: left;
}

.gallery1{
	td{
		img{
			padding-bottom: 20px;
		}
	}
}


/* 	------------------------- Footer CSS ----------------------------- */

footer{
	background: url('../img/bg-footer.jpg');
	
}

.register{
	font-family: $lato;	
	color: #fff;
	a{
		color: #fff;
		
	}
	
	h1{
		font-size: 6rem;
	}
	
	h1,h3{
		font-family: $lato;
	}
}

.register-common{
	h4{
		color: $primary-color;
		font-family: $lato;
		font-size: 21px;
		&:hover{
			text-decoration: none;
		}
	}
	padding: 6px 0;
}

.register-down{
	
	background-color: $primary-color;
	color: #fff;		
	
}

.register-desc{
	color: #fff;
	font-size: 1.9rem;
	font-family: $lato;
	
}

.branding{
	color: $primary-color;
	a{
		color: $primary-color;
		&:hover{
			color: #ffffff;
		}
	}
}

.carousel-caption{
	padding-bottom: 60px !important;
	left: 20px;
	right: 20px;
}

/* 	------------------------- Reusable CSS ----------------------------- */

.nopadding{
	padding: 0;
}

.nopadding-right{
	padding-right: 0;
}

.nopadding-left{
	padding-left: 0;
}

.vmiddle {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.white-space-sm{
	padding:15px 0;
}

.white-space-md{
	padding:30px 0;
}

.white-space-lg{
	padding:30px 0;
}

.white-space-indulge{
	padding-top: 195px; 
}

.result, .result2{
	color: #fff;
}


.ui-draggable .ui-dialog-titlebar{
	background: none;
	border: 0;
}

.ui-widget.ui-widget-content{
	border: none;
	
}

.read-more-content{
	padding: 30px;
	text-shadow: none;
	color: #000000;
	opacity: 0.8;
	border-radius: 0;
	
		h3{
			color: $primary-color;
			font:bold 27px $lato;
			margin: 0;
			padding: 0;
		}
		ul{
			font-family: $mada;
			font-weight: lighter;
			margin: 0 0 0 18px;
			padding: 0;
			li{
				line-height: 1.5em;
			}
		}
}


/* Vanilla JS modal */
.modal {
  display: none;
}

.vanilla-modal .modal {
  display: block;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s, z-index 0s 0.2s;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.vanilla-modal .modal > * {
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  text-align: left;
}

.vanilla-modal .modal:before {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.vanilla-modal.modal-visible .modal {
  z-index: 99;
  opacity: 1;
  transition: opacity 0.2s;
}

.modal-inner {
  position: absolute;
	left: 30px;
	bottom: 90px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: 99999;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s, z-index 0s 0.2s;
	.modal-content{
		border: 0;
		border-radius: 0;
	}
}
.modal-visible .modal-inner {
  z-index: 99999;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s, transform 0.2s;
}

[data-modal-close] {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 25px;
  cursor: pointer;
  text-align: center;
 
}

.logo-wrapper{
	height: 100vh;
}

.flexslider .slides img{
	width: 500px; 
	margin: 0 auto;
	z-index: 1;
	
}

.brochure-spacing{
	margin-top:260px;
}

.lazy{
	max-width: 100%; 
	height: auto;
	display:block;
}

/* Slick nav  */

.slicknav_menu{
	background: #1b4e5d;
	display: none;
	font-family: $lato;
	position: fixed;
	width: 100%;
	z-index: 5;

}

.slicknav_nav{

	li{
		text-align: center;
	}

	a{
		&:hover{
			border-radius:0;
		}
	}
}

/* 	------------------------- Testing CSS ----------------------------- */

